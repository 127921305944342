export const TOKEN_KEY = 'token@oci';

export function userAuthenticated() {
  return typeof window.sessionStorage !== 'undefined' && window.sessionStorage.getItem(TOKEN_KEY) !== null;
}
export function getToken() {
  return typeof window.sessionStorage !== 'undefined' ? window.sessionStorage.getItem(TOKEN_KEY) : null;
}

export const login = (token) => {
  if (typeof window.sessionStorage !== 'undefined') window.sessionStorage.setItem(TOKEN_KEY, token);
};
export const logout = () => {
  if (typeof window.sessionStorage !== 'undefined') window.sessionStorage.removeItem(TOKEN_KEY);
};

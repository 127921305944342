import axios from 'axios';
import { getToken } from './auth';

const api = axios.create({
  baseURL: 'https://api.oci.org.br',
});

api.interceptors.request.use(async (config) => {
  const authorizedConfig = config;
  const token = getToken();
  if (token) {
    // Adiciona header Authorization conforme
    // esperado pela API
    authorizedConfig.headers.Authorization = `Bearer ${token}`;
  }
  return authorizedConfig;
});

export default api;
